export class Context {
    private static globalContext: IContext;

    static get(): IContext {
        return this.globalContext;
    }

    static async refresh() {
        var response = await fetch("/Context/Get");
        var context = await response.json() as IContext;
        this.setContext(context);
    }

    private static setContext(newContext: IContext) {
        this.globalContext = newContext;
    }
}

export interface IContext {
    apiUrl: string;
}