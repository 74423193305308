import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import { Context } from "./Configuration/Context";

import "./Styles/bundle.scss";

Context.refresh()
    .then(() => {
        const rootElement = document.getElementById("root")!;
        const root = createRoot(rootElement);
        root.render(
            <BrowserRouter>
                <App />
            </BrowserRouter>
        );
    });