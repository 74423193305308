// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Styles/Images/Login.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,400;1,500;1,600;1,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EkKPLrWQN0qVSbIjinuu{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.EkKPLrWQN0qVSbIjinuu .iy7YBKwjSB6jhH7zSn28{width:400px;max-width:95%;max-height:90%;overflow-y:auto}.EkKPLrWQN0qVSbIjinuu .yMBM2mPrYQoyW67HNXNS{text-align:center}.EkKPLrWQN0qVSbIjinuu .IwPpTsYro83YpH6fbegl{width:100%}", "",{"version":3,"sources":["webpack://./Client/Pages/Authenticate.module.scss"],"names":[],"mappings":"AACA,sBACI,wDAAA,CACA,qBAAA,CAEA,4CACI,WAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CAGJ,4CACI,iBAAA,CAGJ,4CACI,UAAA","sourcesContent":["@import \"~/Client/Styles/theme.scss\";\n.loginSurround {\r\n    background-image: url(\"../Styles/Images/Login.png\");\r\n    background-size: cover;\r\n\r\n    .centerCard {\r\n        width: 400px;\r\n        max-width: 95%;\r\n        max-height: 90%;\r\n        overflow-y: auto;\r\n    }\r\n\r\n    .centered {\r\n        text-align: center;\r\n    }\r\n\r\n    .fullWidth {\r\n        width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginSurround": "EkKPLrWQN0qVSbIjinuu",
	"centerCard": "iy7YBKwjSB6jhH7zSn28",
	"centered": "yMBM2mPrYQoyW67HNXNS",
	"fullWidth": "IwPpTsYro83YpH6fbegl"
};
export default ___CSS_LOADER_EXPORT___;
