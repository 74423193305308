import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { AccountClient, ApplicationClient, EmailStatus, LoginResponse, LoginResult } from "../ApiClient/ApiClient";
import { Alert, Button, Card, CenterCover, CoverType, Form, Input, Loading, NoInput } from "@kaladel/react/Components";
import styles from "./Authenticate.module.scss";
import { Severity } from "@kaladel/react/Utilities";
import { useValidator } from "@kaladel/react/Hooks";

enum AuthenticatePhase {
    Email, Login, Register, Verify
};

export const Authenticate: React.FC = (): JSX.Element => {
    const validator = useValidator();

    const accountClient = new AccountClient();

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [appId] = React.useState<string | null>(searchParams.has("appId") ? searchParams.get("appId") : null);
    const [appName, setAppName] = React.useState<string | null>(null);

    const [phase, setPhase] = React.useState<AuthenticatePhase>(AuthenticatePhase.Email);

    const [isBusy, setIsBusy] = React.useState<boolean>(false);

    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [givenName, setGivenName] = React.useState<string>("");
    const [familyName, setFamilyName] = React.useState<string>("");
    const [validationCode, setValidationCode] = React.useState<string>("");

    const handleEmailSubmit = () => {
        if (!validator.canSubmit()) return;

        setIsBusy(true);
        accountClient
            .checkEmail({
                email
            })
            .then(response => {
                switch (response.result.status) {
                    case EmailStatus.Local: {
                        validator.reset();
                        setPhase(AuthenticatePhase.Login);
                        break;
                    }
                    case EmailStatus.NotFound: {
                        validator.reset();
                        setPhase(AuthenticatePhase.Register);
                        break;
                    }
                    case EmailStatus.Delegated: {
                        // not yet supported
                        if (!!response.result.redirect) {
                            location.href = response.result.redirect;
                        }
                    }
                }
                setIsBusy(false);
            });
    };

    const handleLoginSubmit = () => {
        if (!validator.canSubmit()) return;
        setIsBusy(true);
        accountClient
            .login({
                email,
                password,
                queryString: window.location.search
            }, appId!)
            .then(response => {
                handleLoginResult(response.result);
                setIsBusy(false);
            });
    };

    const handleRegisterSubmit = () => {
        if (!validator.canSubmit()) return;
        setIsBusy(true);
        accountClient
            .register({
                email,
                password,
                confirmPassword,
                givenName,
                familyName,
                queryString: window.location.search
            }, appId!)
            .then(response => {
                handleLoginResult(response.result);
                setIsBusy(false);
            });
    };

    const handleValidateSubmit = async () => {
        if (!validator.canSubmit()) return;
        setIsBusy(true);
        await accountClient
            .validate({
                email,
                validationCode
            }, appId!)
            .then(response => {
                handleLoginResult(response.result);
                setIsBusy(false);
            });
    };

    const handleLoginResult = (loginResult: LoginResponse) => {
        switch (loginResult.result) {
            case LoginResult.AwaitingValidation: {
                setPhase(AuthenticatePhase.Verify);
                break;
            }
            case LoginResult.Success: {
                location.href = loginResult.redirect!;
                break;
            }
            case LoginResult.Failure: {
                validator.addCustomError("password", "Incorrect password");
                break;
            }
            case LoginResult.ValidationFailed: {
                validator.addCustomError("validationCode", "Incorrect validation code");
                break;
            }
            case LoginResult.ValidationExpired: {
                validator.addCustomError("validationCode", "Validation code expired");
                break;
            }
        }
    };

    const handleChangeEmail = () => {
        validator.reset();
        setPhase(AuthenticatePhase.Email);

        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setGivenName("");
        setFamilyName("");
        setValidationCode("");
    };

    React.useEffect(() => {
        if (!!appId) {
            let appClient = new ApplicationClient();
            appClient
                .getName(appId)
                .then(response => {
                    setAppName(response.result);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <CenterCover coverType={CoverType.Relative} backgroundClassName={styles.loginSurround}>
            <Card className={styles.centerCard}>
                {loading &&
                    <div className={styles.centered}>
                        <Loading />
                    </div>
                }
                {!loading && (
                    <>
                        {(!appId || !appName) && (
                            <>
                                <h2>An error occurred</h2>

                                <div>We don't recognise the application that sent you here.</div>
                            </>
                        )}
                        {!!appId && !!appName && (
                            <>
                                {/* Email Entry */}
                                {phase === AuthenticatePhase.Email && (
                                    <>
                                        <h2>Login/Register</h2>
                                        <h5>{appName}</h5>

                                        <hr />

                                        <Form>
                                            <fieldset disabled={isBusy}>
                                                <Input
                                                    id="email"
                                                    label="Email address"
                                                    type="email"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    autoFocus={true}
                                                />

                                                <Button
                                                    severity={Severity.Primary}
                                                    onClick={() => handleEmailSubmit()}
                                                    type="submit"
                                                    isBusy={() => isBusy}
                                                    busyText="Please wait..."
                                                    className={styles.fullWidth}
                                                >
                                                    Continue
                                                </Button>
                                            </fieldset>
                                        </Form>
                                    </>
                                )}

                                {/* Password Entry */}
                                {phase === AuthenticatePhase.Login && (
                                    <>
                                        <h2>Login</h2>
                                        <h5>{appName}</h5>

                                        <div><b>Hi {email}!</b> <span className="small"><a href="#" onClick={() => handleChangeEmail()}>Not you?</a></span></div>

                                        <hr />

                                        <Form>
                                            <fieldset disabled={isBusy}>
                                                <Input
                                                    id="password"
                                                    label="Password"
                                                    type="password"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    autoFocus={true}
                                                />

                                                <Button
                                                    severity={Severity.Primary}
                                                    onClick={() => handleLoginSubmit()}
                                                    type="submit"
                                                    isBusy={() => isBusy}
                                                    className={styles.fullWidth}
                                                >
                                                    Login
                                                </Button>
                                            </fieldset>
                                        </Form>
                                    </>
                                )}

                                {/* Registration */}
                                {phase === AuthenticatePhase.Register && (
                                    <>
                                        <h2>Register</h2>
                                        <h5>{appName}</h5>

                                        <div><b>Hi {email}!</b> <span className="small"><a href="#" onClick={() => handleChangeEmail()}>Not you?</a></span></div>

                                        <hr />

                                        <Form>
                                            <fieldset disabled={isBusy}>
                                                <Input
                                                    id="password"
                                                    label="Password"
                                                    type="password"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                    autoFocus={true}
                                                />

                                                <Input
                                                    id="confirmPassword"
                                                    label="Confirm password"
                                                    type="password"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Repeat the password"
                                                    value={confirmPassword}
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                />

                                                <Input
                                                    id="givenName"
                                                    label="Given name"
                                                    type="text"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Enter your given name"
                                                    value={givenName}
                                                    onChange={e => setGivenName(e.target.value)}
                                                />

                                                <Input
                                                    id="familyName"
                                                    label="Surname"
                                                    type="text"
                                                    validator={validator}
                                                    validations={{ required: true }}
                                                    placeholder="Enter your surname"
                                                    value={familyName}
                                                    onChange={e => setFamilyName(e.target.value)}
                                                />

                                                <Button
                                                    severity={Severity.Primary}
                                                    onClick={() => handleRegisterSubmit()}
                                                    type="submit"
                                                    isBusy={() => isBusy}
                                                    className={styles.fullWidth}
                                                >
                                                    Register
                                                </Button>
                                            </fieldset>
                                        </Form>
                                    </>
                                )}

                                {/* Verification */}
                                {phase === AuthenticatePhase.Verify && (
                                    <>
                                        <h2>Verify</h2>
                                        <h5>{appName}</h5>

                                        <div><b>Hi {email}!</b> <span className="small"><a href="#" onClick={() => handleChangeEmail()}>Not you?</a></span></div>

                                        <hr />

                                        <Form>
                                            <Alert
                                                severity={Severity.Info}
                                            >
                                                A validation code has been sent to your email. It will expire in 60 minutes.
                                            </Alert>

                                            <Input
                                                id="givenName"
                                                label="Validation code"
                                                type="text"
                                                validator={validator}
                                                validations={{ required: true }}
                                                placeholder="Enter your validation code"
                                                value={validationCode}
                                                onChange={e => setValidationCode(e.target.value)}
                                            />

                                            <Button
                                                severity={Severity.Primary}
                                                onClick={() => handleValidateSubmit()}
                                                type="submit"
                                                isBusy={() => isBusy}
                                                className={styles.fullWidth}
                                            >
                                                Validate
                                            </Button>
                                        </Form>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Card>

        </CenterCover>
    );
};