import * as React from "react";
import { Layout } from "./Layout";
import { Authenticate } from "./Pages/Authenticate";

export const App: React.FC = (): JSX.Element => {
    return (
        <Layout>
            <Authenticate />
        </Layout>
    );
};
