import { Context } from "../Configuration/Context";

export class ApiBase {
    protected transformOptions = async (options: RequestInit): Promise<RequestInit> => {
        options.credentials = "include";
        return Promise.resolve(options);
    };

    protected getBaseUrl = (_: string, _baseUrl?: string): string => {
        return Context.get().apiUrl;
    }

    protected transformResult(_url: string, response: Response, processor: (response: Response) => any) {
        if (response.status === 401) {
            localStorage.removeItem("token");
            location.href = "/";
        }

        return processor(response);
    }
}