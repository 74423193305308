import { Content, TopBar } from "@kaladel/react/Components";
import styles from "Layout.module.scss";
import * as React from "react";

interface ILayoutProps {
    children?: React.ReactNode;
}

export const Layout: React.FunctionComponent<ILayoutProps> = (props): JSX.Element => {
    return (
        <>
            <TopBar
                appName="Kaladel Account"
                className={styles.topBar}
                homeLink="/"
            />
            <Content>
                {props.children}
            </Content>
        </>
    );
};